import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Strong, Icon, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { IoIosSchool } from "react-icons/io";
import { MdAirplanemodeActive, MdAccountBalance } from "react-icons/md";
import { FaGlassWhiskey, FaTape, FaPeopleCarry } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Bos Balance: Financial Expert for Expats & Freelancers in the Netherlands
			</title>
			<meta name={"description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:title"} content={"Bos Balance: Financial Expert for Expats & Freelancers in Netherlands"} />
			<meta property={"og:description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
		</Helmet>
		<Components.TopNavBar />
		<Section padding="180px 0 150px 0" background="linear-gradient(180deg,rgba(2, 48, 71, 0.81) 0%,rgba(2, 48, 71, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1562240020-ce31ccb0fa7d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000) 0/cover repeat scroll padding-box" sm-padding="120px 0 124px 0" quarkly-title="Hero-15">
			<Text
				margin="0px 0px 30px 0px"
				text-align="center"
				color="--light"
				font="normal 400 18px/1.5 --fontFamily-sans"
				letter-spacing="1px"
			>
				NETHERLANDS
			</Text>
			<Text
				margin="0px 0px 50px 0px"
				text-align="center"
				font="--headline1"
				letter-spacing="1px"
				color="#ffb703"
				padding="0px 250px 0px 250px"
				lg-padding="0px 0 0px 0"
				md-font="normal 700 42px/1.2 --fontFamily-sans"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
				as="h1"
			>
				About Me
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				color="#c8ced8"
				text-align="center"
				padding="0px 250px 0px 250px"
				font="--lead"
				lg-padding="0px 0 0px 0"
			>
				Your Personal Financial Guide in the Netherlands
			</Text>
			<Box min-width="100px" min-height="100px" text-align="center" margin="50px 0px 0px 0px">
				<Components.QuarklycommunityKitPopup>
					<Override slot="Wrapper" />
					<Override slot="Content" background="--color-primary" />
					<Override slot="Button Close" color="--light" />
					<Override
						slot="Button Open"
						href="#"
						color="--light"
						text-align="center"
						padding="25px 50px 25px 50px"
						font="--lead"
						background="--color-secondary"
						hover-background="--color-selectiveYellow"
						text-decoration="none"
					>
						CONTACT ME
					</Override>
					<Box
						lg-margin="20px 0px 0px 0px"
						background="--color-darkL2"
						md-padding="28px 20px 28px 20px"
						sm-padding="28px 30px 28px 30px"
						lg-width="100%"
						padding="36px 36px 48px 36px"
					>
						<Components.EmailForm />
					</Box>
				</Components.QuarklycommunityKitPopup>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="60px 0 60px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			background="--color-primary"
			quarkly-title="Advantages/Features-6"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 48px 0px"
				lg-justify-content="center"
			>
				<Text as="p" font="--base" color="--selectiveYellow" margin="0px 0px 8px 0px">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						ABOUT BLAISE
					</Strong>
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--light"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					lg-text-align="center"
					lg-width="100%"
				>
					I'm Blaise, the founder of Bos Balance. My goal is to help you navigate the financial landscape with a blend of
			professional expertise and a personal approach.
				</Text>
			</Box>
			<Text font="--headline2" color="--selectiveYellow" as="h2">
				Background and Expertise
			</Text>
			<List
				margin="20px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				as="ul"
				sm-margin="24px 0 0 0"
				list-style-type="none"
				font="normal normal 18px/150% sans-serif"
				display="grid"
				flex-direction="column"
				grid-gap="24px 32px"
				lg-margin="32px 0px 0px 0px"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="ai"
						icon={AiOutlineSafetyCertificate}
						size="20px"
						color="--skyBlue"
						margin="5px 0 0 0"
						font="20px/27px sans-serif"
					/>
					<Text padding="0" margin="0" color="--light">
						<Strong>
							Chartered Accountant:
						</Strong>
						I am accredited with the ACA designation.
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="io"
						icon={IoIosSchool}
						size="20px"
						color="--skyBlue"
						margin="5px 0 0 0"
						font="22px/27px sans-serif"
					/>
					<Text padding="0" margin="0" color="--light" font="18px/27px --fontFamily-sans">
						<Strong>
							Educational Foundation:
						</Strong>
						I hold a Bachelor's Degree in Accounting & Finance.
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdAirplanemodeActive}
						size="20px"
						color="--skyBlue"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" color="--light">
						<Strong>
							International Experience:{" "}
						</Strong>
						After completing my studies with Chartered Accountants Ireland in 2019, I relocated to the Netherlands, gaining
				firsthand experience in the complexities of its financial system.
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdAccountBalance}
						size="20px"
						color="--skyBlue"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" color="--light" font="18px/27px --fontFamily-sans">
						<Strong>
							Expert in Dutch Financial Regulations:
						</Strong>
						Specialising in the local tax system, I offer guidance to both expats and local residents.
					</Text>
				</Box>
			</List>
		</Section>
		<Section padding="80px 0 80px 0" background="--color-light" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				align-items="flex-start"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--secondary"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					as="h2"
				>
					My Mission
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--primary"
					md-text-align="center"
					text-align="center"
					max-width="480px"
				>
					Simplifying Your Financial Journey with Care
				</Text>
				<Box min-width="100px" min-height="100px">
					<Text
						as="p"
						font="--lead"
						font-size="20px"
						font-weight="300"
						color="--primary"
						width="50%"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						md-padding="0px 0px 0px 0px"
						sm-font="--base"
						lg-text-align="center"
						lg-width="100%"
					>
						Bos Balance is my commitment to providing tailored financial services. Whether you're settling in the
				Netherlands or tackling freelance finances, I understand the challenges and am here to help.
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				align-self="center"
				display="flex"
				align-items="center"
			>
				<Text font="--headline3" color="--primary" as="h3">
					Ethical and Personalised Service
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					flex-direction="column"
					display="flex"
					align-items="center"
				>
					<Icon
						category="fa"
						icon={FaGlassWhiskey}
						margin="0px 0px 30px 0px"
						color="--secondary"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--primary" font="--lead" lg-text-align="left">
						I believe in maintaining integrity and transparency in all my work.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
					align-items="center"
				>
					<Icon
						category="fa"
						icon={FaTape}
						margin="0px 0px 30px 0px"
						color="--secondary"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--primary" font="--lead" lg-text-align="left">
						Understanding that each client has unique needs, I tailor my services to suit your specific financial
				circumstances.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
					align-items="center"
				>
					<Icon
						category="fa"
						icon={FaPeopleCarry}
						margin="0px 0px 30px 0px"
						color="--secondary"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--primary" font="--lead" lg-text-align="left">
						For me, it's not just about numbers; it's about building a supportive, long-term relationship with you.
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				justify-items="center"
				margin="50px 0px 0px 0px"
			>
				<Text
					as="p"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--primary"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					lg-text-align="center"
					lg-width="100%"
				>
					Choosing Bos Balance means partnering with someone dedicated to your financial well-being in the Netherlands.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bf71cb867c86001f1b6703"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});